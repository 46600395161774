import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import React from "react";
import { IconButton } from "@mui/material";
const BankIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <AccountBalanceIcon />
    </IconButton>
  );
};

export default BankIconComponent;
