import React from "react";
import "./style.scss";
import AvatarComponent from "../../library-based-components/AvatarComponent";
import { useAuth } from "../../../providers/AuthProvider";
import MenuIconComponent from "../../library-based-components/icons/MenuIconComponent";
import MenuClosedIconComponent from "../../library-based-components/icons/MenuClosedIconComponent";
import { useMobileViewState } from "../../../providers/MobileViewStateProvider";
import { IconButton, Tooltip } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const Header = () => {
  const auth = useAuth();
  const { mobileView, mobileViewSwitch } = useMobileViewState();

  if (!auth.user) return null;

  return (
    <div className="Header">
      {mobileView && <MenuClosedIconComponent onClick={mobileViewSwitch} />}
      {!mobileView && <MenuIconComponent onClick={mobileViewSwitch} />}
      <AvatarComponent src={auth.user.pic} />
      <span>{auth.user.name}</span>
      <Tooltip
        title="Log out"
        style={{ marginLeft: "auto" }}
        onClick={() => auth.signout()}
      >
        <IconButton>
          <PowerSettingsNewIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Header;
