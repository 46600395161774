import React from "react";
import "./style.scss";
import RequireAuth from "../../../pages/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { withErrorBoundary } from "../../../hocs/withErrorBoundary";
import { ShowConfirmProvider } from "../../../providers/ShowConfirmProvider";
import { MobileViewStateProvider } from "../../../providers/MobileViewStateProvider";
import MainContentWrapper from "./MainContentWrapper";
import { FireworkProvider } from "../../../providers/FireworkProvider";

const queryClient = new QueryClient();
const MainContent = () => {
  return (
    <RequireAuth>
      <ShowConfirmProvider>
        <MobileViewStateProvider>
          <FireworkProvider>
            <QueryClientProvider client={queryClient}>
              <MainContentWrapper />
            </QueryClientProvider>
          </FireworkProvider>
        </MobileViewStateProvider>
      </ShowConfirmProvider>
    </RequireAuth>
  );
};

export default withErrorBoundary(MainContent);
