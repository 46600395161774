import React from "react";
import { IconButton } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const DollarIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <AttachMoneyIcon />
    </IconButton>
  );
};

export default DollarIconComponent;
