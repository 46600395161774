export const USER_URLS = {
  SETTINGS: "/settings",
  FAMILY: "/family",
  TO_BUY: "/to-buy",
  PURCHASES: "/purchases",
  STATISTIC: "/statistic",
};

export const PUBLIC_URLS = {
  LOGIN: "/login",
  HOME: "/",
  SEND_REQUEST: "/sendRequest",
  ACCEPT_INVITE: "/acceptInvite",
  RESET_PASS: "/resetPass",
};

export const ADMIN_URLS = {
  INQUIRIES: "/inquiries",
  CREATION: "/creation",
  FAMILIES: "/families",
};
