import { USER_ROLES } from "../constants/USER_ROLES";
import { COMMON } from "../constants/COMMON";
import { EntityModel } from "./EntityModel";
import { DBService } from "../services/DBService";
import { COLLECTIONS } from "../constants/COLLECTIONS";

export class UserModel extends EntityModel {
  static collection = COLLECTIONS.USERS;
  constructor(user) {
    super(user);
    this.name = user.name || "My name";
    this.firebaseId = user.firebaseId;
    this.pic = user.pic || COMMON.NO_PIC;
    this.role = user.role || USER_ROLES.USER;
    if (user.role !== USER_ROLES.ADMIN) {
      this.familyId = user.familyId ?? "";
      this.startDate = user.startDate ?? 1;
    }
  }

  validateCustom(user) {
    if (!user.firebaseId) {
      throw new Error("no firebaseId");
    }
    if (!user.role) {
      throw new Error("no role for this user");
    }
    if (!user.name) {
      throw new Error("no name for this user");
    }
    if (!user.pic) {
      throw new Error("no pic for this user");
    }
  }

  getStartDate() {
    return this.startDate;
  }
  static async resetPass(email) {
    return DBService.sendPasswordResetEmail(email);
  }

  setFamilyId(familyId) {
    this.familyId = familyId;
  }

  /**
   * This will login user automatically>}
   */
  static async createByEmailAndPassword({ email, password }) {
    const firebaseUser = await DBService.createUserByEmailAndPassword(email);
    const user = new UserModel({
      firebaseId: firebaseUser.user.uid,
      id: firebaseUser.user.uid,
    });
    await user.update();
  }

  update() {
    this.validate(this);
    return DBService.saveDocumentById(this, UserModel.collection);
  }

  saveFamilyId(familyId) {
    this.setFamilyId(familyId);
    return this.update();
  }

  static async deleteEntity(id) {
    return DBService.removeDocument(UserModel.collection, id);
  }

  static async findFamilyMember(familyId) {
    return DBService.getDocumentWhere(
      UserModel.collection,
      "familyId",
      familyId,
    );
  }

  static async findFamilyMembers(familyId) {
    return DBService.getDocumentsWhere(
      UserModel.collection,
      "familyId",
      familyId,
    );
  }
}
