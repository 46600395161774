import React, { useCallback } from "react";
import ParticlesComponent from "../components/fireworks/ParticlesComponent";

const FireworkContext = React.createContext(null);

export const FireworkProvider = ({ children }) => {
  const [init, setInit] = React.useState(false);

  const showFirework = useCallback(() => {
    setInit(true);
    setTimeout(() => setInit(false), 7000);
  }, []);

  return (
    <FireworkContext.Provider value={showFirework}>
      {init && <ParticlesComponent />}
      {children}
    </FireworkContext.Provider>
  );
};

export const useFirework = () => {
  const contextValue = React.useContext(FireworkContext);
  if (!contextValue) {
    throw new Error("Tried to use context from outside the provider");
  }
  return contextValue;
};
